@media (min-width: 601px) {
    .footer {
        /* position: absolute;
        top: 90vh;
        left: 0;
        right: 0; */
        position: fixed;
        width: 100%;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
    }
    }
    .feedbackLinkText {
        color: var(--main-text-color);
    }
    @media only screen and (max-width: 600px) and (min-width: 310px) {
    .footer {
        margin: 4px;
    }
    .footer a{
        margin: auto !important;
    }
    .footer .epiCopyRight{
        margin: 5px;
    }
    }
    @media only screen and (max-width: 1125px) and (min-width: 700px) {
        .footer{
            margin: 6px 0;
        }
    }