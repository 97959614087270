/* Original theme */
:root {
    --epipbbackground: white;
    --title-text: #005792;
    --subtitle-text: #1CA8DD;
    --video-icon-border:#005792;
    --video-icon-playbutton: #005792;
}

/* Orange theme */
/* :root {
    --epipbackground: orange;
    --title-text: #005792;
    --subtitle-text: white;
    --video-icon-border: white;
    --video-icon-playbutton: purple;
} */

.sectionTitle {
    margin-top: 1vh;
    display: flex;
    color: var(--title-text);
    /* color: #ffffff; */
    flex-direction: column;
    position: relative;
    /* left: 4%;
    right: 90%; */
    /* top: 17.72%; */
    /* top: 25%;
    bottom: 65.24%; */
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: 420;
    /* font-size: 48px; */
    font-size: 3vw;
    /* line-height: 50px; */
    line-height: 3vw;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.subSectionTitle {
    /* color: #1CA8DD; */
    color: var(--subtitle-text);
    display: flex;
    flex-direction: column;
    position: relative;
    /* left: 4%;
    right: 90%; */
    /* top: 17.72%; */
    /* top: 25%;
    bottom: 65.24%; */
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: 1vh; */
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    /* font-size: 48px; */
    font-size: 2vw;
    /* line-height: 50px; */
    line-height: 3vw;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    /* border-bottom: 1px solid var(--accent-underline); */
    border-top: 0px;
    border-bottom: 1px solid transparent;
    /* -moz-border-image: -moz-linear-gradient(to right, white, #1CA8DD, white);
    -webkit-border-image: -webkit-linear-gradient(to right, white, #1CA8DD, white);
    border-image: linear-gradient(to right, white, #1CA8DD, white); */
    -moz-border-image: -moz-linear-gradient(to right, white, lightgrey, white);
    -webkit-border-image: -webkit-linear-gradient(to right, white, lightgrey, white);
    border-image: linear-gradient(to right, white, lightgrey, white);
    border-image-slice: 1;
}

.epiplaybooks {
    background-color: var(--epipbbackground);
    align-items: center;
}