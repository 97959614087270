@import "./EpiPlaybooks.css";

.videoCaption {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: 100;
    font-size: 1.5vw;
    line-height: 2rem;
    justify-content: center;
    align-items: center;
}

.screenIcon {
    border: 2px solid var(--video-icon-border);
    border-radius: 4px;
    height: 45px;
    width: 80px;
    cursor: pointer;
}