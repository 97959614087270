.BannerText {
    color: #72869F;
    font-size: xxx-large;
    text-align: center;
    font-weight: 1;
}

.BannerTextHighlight {
    color: #F4881C;
    font-size: xxx-large;
    text-align: center;
    font-weight: bold;
}

.mainPage {
    margin-bottom: 5vh;
    margin-top: 3rem;
}

.mainPageLargeText {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    font-size: calc(2.3vh + 2.4vw);
    line-height: calc(2.3vh + 2.4vw);
}

.mainPageText {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 2vw;
    line-height: 3vw;
    justify-content: center;
    align-items: center;
}

.mainsplash {
    background-color: white;
    /* padding-top: 0vh;
    padding-bottom: 0vh; */
}

.smallPrint {
    color: var(--main-text-secondary);
    font-size: 2vh
}

.highlight1 {
    color: var(--highlight2-text-color)
}
.highlight2 {
    color: var(--highlight2-text-color)
}
.highlight3 {
    color: var(--highlight2-text-color)
}

.mainPageTryPlaybooks {
    position: relative;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 2vw;
    line-height: 3.5vw;
}

.mainPageCarousel {
    display: none;
    position: relative;
    width: 60vw;
    border: 0px solid #D7E4EB;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 50px rgba(187, 221, 235, 0.25));
}

.carouselButton {
    border-radius: 50%;
    border: solid;
}

.explorePlaybooksButton {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
    width: 190px;
}

.learnMoreButton {
    margin-right: auto;
    color: var(--highlight-text-color);
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 158.19%;
    background-color: var(--main-bg-color);
    border-radius: 4px;
    width: 100px;
    text-align: center;
    padding: 0px;
    cursor: pointer;
}

.tryButton {
    color: #FFFFFF;
    font-family: Roboto;
    font-style: normal;
    font-weight: 2;
    font-size: 20px;
    line-height: 190%;
    background-color: var(--highlight-text-color);
    border-radius: 2px;
    width: 100px;
    text-align: center;
    padding: 10px;
}

.tryButtonInset {
    color: #FFFFFF;
    font-family: Roboto;
    font-style: normal;
    font-weight: 2;
    font-size: 20px;
    line-height: 190%;
    background-color: var(--highlight-text-color);
    border-radius: 2px;
    width: 100px;
    height: 40px;
    text-align: center;
    padding: 5px;
    margin-top: 5px;
}

.trySampleButton {
    width: fit-content;
    color: var(--main-bg-color);
    font-family: Roboto;
    font-style: normal;
    font-weight: 8;
    font-size: 2.5vh;
    line-height: 158.19%;
    background-color: var(--info-button-bg-color);
    border-radius: 4px;
    text-align: center;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.mainTextBlock {
    display: flex;
    height: 45vh;
    max-height: 400px;
    flex-direction: column;
    /* margin-left: 4vw; */
    text-align: center;
    justify-content: center;
    align-items: center;
}

.mainPageCaptionText {
    color: var(--highlight-text-color);
}

.mainPageTitle {
    margin-top: 0.5rem;
    width: 100vw;
}

.captionText {
    font-family: Roboto;
    font-style: normal;
    font-weight: 2;
    margin-top: 1rem;
    font-size: 1.4rem;
}

.mainPageContent {
    margin-top: 2rem;
    width: 100vw;
    height: 60vh;
}

.animGIF {
    border-radius: 0.5rem;
    border: 1px solid var(--image-border-color);
    width: auto;
    height: 66vh;
    min-height: 200px;
    min-width: 200px;
}

.screenShot {
    border-radius: 0.5rem;
    border: 1px solid var(--image-border-color);
    max-width: 38vw;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.centerize {
    display: flex;
    flex-direction: column;
    height: fit-content;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.buttonStyle {
    margin: 0.5rem;
}

/* This prevents the animated gif from showing edges of
   previous and next gifs when carousel is rotated */
.rec-slider-container {
    margin: 0 0 !important;
}

/* Styling required by video modal */
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.display-block {
    display: block;
}
  
.display-none {
    display: none;
}

.vidCloseButton {
    position: fixed;
    top: 0%;
    left: calc(100% - 25px);
    width: 100px;
    background-color: rgba(187, 221, 235, 0.25);
    color: var(--info-button-bg-color);
    border-radius: 50%;
    cursor: pointer;
}

.playbookNode {
    border-color: var(--main-text-color);
    padding: 10px;
    padding-left: 20px;
    padding-right: 15px;
    background-color: white;
    border-left: #F4881C;
    border-left-width: 10px;
    border-left-style: solid;
    border-radius: 3px;
    height: 10vh;
    min-height: 100px;
}

.pbNodeTriangle {
    width: auto;
    height: 2rem;
    margin-top: 0.5rem;
    margin-right: 10px;
}

.videoContainer {
    width: 75vw;
    height: auto;
    border: 3px solid var(--info-button-bg-color);
    border-radius: 4px;
}

.rec-carousel-wrapper {
    max-height: 400px;
}

.rec-carousel {
    max-height: 400px;
}

.rec-item-wrapper {
    max-height: 400px;
}

.moreContentArrow {
    color: purple;
    position: fixed;
    top: 84vh;
    left: 0;
    right: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 5vw;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
    50% {
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px); }
    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); } }
  
  @keyframes pulse {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
    50% {
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px); }
    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); } }

@media only screen and (max-width: 1025px) and (min-width: 700px) {
.mainPageLargeText {
    font-weight: 600;
    font-size: 2vw;
    line-height: 3vw;
}
}

@media only screen and (max-width: 600px) and (min-width: 310px) {
    .mainTextBlock{
        height: auto !important;
        padding: 10px 0 !important;
    }
    .mainsplash {
        padding-top: 5vh;
    }
}
