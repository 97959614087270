.header {
    position: fixed;
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: 4rem;
    background-color: var(--main-bg-color);
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 10;
}