/* Original theme */
:root {
    --epidocsbackground: #E0E0E0;
    --title-text: #005792;
    --subtitle-text: #1CA8DD;
    --video-icon-border:#005792;
    --video-icon-playbutton: #005792;
}
/* Orange theme */
/* :root {
    --epidocsbackground: orange;
    --title-text: #005792;
    --subtitle-text: white;
    --video-icon-border: white;
    --video-icon-playbutton: purple;
} */
.sectionTitle {
    /* margin: 4% 0 !important; */
    margin-top: 1vh;
    display: flex;
    color: var(--title-text);
    flex-direction: column;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: 420;
    font-size: 3vw;
    line-height: 3vw;
    justify-content: center;
    align-items: center;
}
.subSectionTitleDocs {
    color: var(--subtitle-text);
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: light;
    font-size: 2.5vh;
    line-height: 3vw;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    border-top: 0px;
    white-space: nowrap;
    /* border-bottom: 1px solid transparent; */
    /* -moz-border-image: -moz-linear-gradient(to right, white, #1CA8DD, white);
    -webkit-border-image: -webkit-linear-gradient(to right, white, #1CA8DD, white);
    border-image: linear-gradient(to right, white, #1CA8DD, white); */
    /* -moz-border-image: -moz-linear-gradient(to right, white, lightgrey, white);
    -webkit-border-image: -webkit-linear-gradient(to right, white, lightgrey, white);
    border-image: linear-gradient(to right, white, lightgrey, white); */
    /* border-image-slice: 1; */
}
.docs {
    background-color: var(--epipbbackground);
    /* position: relative; */
    z-index: 5;
}
.clickable {
    cursor: pointer;
}
.giveItATry {
    /* position: fixed; */
    bottom: 15vh;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.screenShotDocs {
    position: absolute;
    top: 20%;
    left: 50%;
    opacity: 0.1;
    /* background-image: url('../../public/assets/bottom-screen1.png'); */
    /* padding: 6px 0; */
}
.moreInfoLinksBox {
    border: 1px solid white;
    background-color: #e0e0e0;
    /* width: 25vw; */
    padding: 10px;
    /* padding-bottom: 25px; */
    border-radius: 4px;
    margin: 7vw 7vw 0 7vw;
}
.accentBackground {
    background-color: whitesmoke;
    position: absolute;
    top: 30vh;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 0;
}
@media only screen and (max-width: 600px) and (min-width: 310px) {
.screenShotDocs{
    background-image: none;
    background-color: white;
}
.subSectionTitleDocs{
    margin: 5px 0;
}
.sectionTitle{
    font-size: 3vh;
    line-height: 3vh;
    margin-top: 8vh;
    /* margin: 4% !important; */
}

.videoCaption{
    font-size: 11px;
    line-height: 12px;
}
.subSectionTitle{
    font-size: 1rem;
}
}