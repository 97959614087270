body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--main-bg-color);
}

.linkText {
  /* color: var(--link-text-color); */
  color: var(--button-link-text-color);
  font-weight: 1;
  cursor: pointer;
  text-decoration: none;
}

.contactLink {
  color: var(--main-text-color);
  margin-left: 1rem
}

.tryItTextLink {
  color: var(--highlight-text-color);
  font-weight: bold;
  cursor: pointer;
}

.loginLink {
  color: var(--main-text-color);
  margin-right: 1rem
}

.flexContainer {
  display: flex;
}

.flexGrow {
  flex-grow: 1;
}

.flexCol {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.flexStart {
  justify-content: flex-start;
}

.flexEnd {
  justify-content: flex-end;
}

.flexCenter {
  justify-content: center;
}

.flexSpaceEvenly {
  justify-content: space-evenly;
}

.flexSpaceBetween {
  justify-content: space-between;
}

.flexBaseline {
  align-items: baseline;
}

.App {
  background-color: var(--main-bg-color);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

html, body {
  background-color: var(--main-bg-color);
}

.App-logo-old {
  /* animation: App-logo-spin infinite 20s linear; */
  margin-top: 10vh;
  height: 48vmin;
}

.App-header {
  /* background-color: #222; */
  background-color: rgb(248, 248, 248);
  height: 100%;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-family: 'Lato', sans-serif;
  font-size: 8vh;
  color: gray;
  background-color: rgb(248, 248, 248);
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 0;
  padding: 0;
}

.epiCopyRight {
  font-size: 0.75em;
  color: var(--main-text-secondary);
}

.grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.3;
}

.homePageBg {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  z-index: -1;
  /* background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url('../public/assets/homepagebg.png');
  mix-blend-mode: hard-light;
  opacity: 0.05; */
}

/* .section{
  text-align: center;
  font-size: 4em;
  color: #fff;
} */
@media only screen and (max-width: 700px) and (min-width: 367px) {
.contactLink {
  margin-left: 0rem !important
}
}