* {
  font-family: 'Roboto', sans-serif;
}

:root {
  /* --main-text-color: #72869F; */
  --main-text-color: #161C2E;
  --main-text-color-alt: #005792;
  --highlight-text-color: #F4881C;
  --highlight2-text-color: orange;
  --main-bg-color: #FFFFFF;
  /* --main-bg-color: #EEF8FC; */
  --link-text-color: #000916;
  --button-link-text-color: white;
  --info-button-bg-color: #005792;
  --image-border-color: white;
  --main-text-secondary: #72869F;
  --video-icon: #005792;
  --accent-underline: #CCDDEE;
}