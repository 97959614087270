  .keepMePostedCard {
    width: 30vw;
    background-color: #ffffff;
    border: 1px solid var(--subtitle-text); 
    /* min-height: 200px; */
    padding: 2vw;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    // align-items: center;]
    margin: 7vw 7vw 0 7vw;
  }
.keepMePostedTitle {
    font-size: 1.5rem;
    padding-left: 1vw;
    font-weight: light;
    color: var(--subtitle-text);
    margin-bottom: 10px;
}
.customSubmitRow {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
form {
    width: 90%;
    background: #ffffff;
    padding: 15px;
    padding-bottom: 0px;
    border: none;
}
.form-group {
    margin-bottom: 0px;
    &.required {
      label:after {
        content: ' *';
      }
    }
    label {
      display: inline-block;
      width: 15%;
      margin-top: 2px;
      margin-right: 5px;
    //   font-weight: 50    0;
      font-size: 1rem;
      color: var(--subtitle-text);
    }
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    select {
      display: inline-block;
      width: 80%;
      height: 34px;
      // padding: 6px 12px;
    //   font-size: 14px;
      font-size: 1rem;
      // line-height: 1.42857143;
      line-height: 1rem;
      color: #555;
      background-color: #ffffff;
      background-image: none;
      border: none;
      border-bottom: 1px solid #CCC;
      box-sizing: border-box;
    }
    input[type=text]:focus {
      outline: none;
      border-bottom: 1px solid lightblue;
    }
}
.validation-error {
    width: 100%;
    font-size: x-small;
    color: red;
    margin: 0px 16%;
    display: inline-block;
}
button {
    padding: 10px 15px;
    border-radius: 4px;
    border: 0px solid grey;
    outline-color: none;
    font-size: medium;
    cursor: pointer;
}
button:enabled {
    color: var(--subtitle-text);
    outline-width: 0;
}
button:disabled {
    color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgb(170, 170, 170));
}
.buttons {
    button {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
}


.customSubmitRow button{
  width: fit-content;
    color: var(--main-bg-color);
    font-family: Roboto;
    font-style: normal;
    font-weight: 8;
    font-size: 1rem;
    line-height: 158.19%;
    background-color: var(--info-button-bg-color);
    border-radius: 4px;
    text-align: center;
    padding: 10px;
}

@media only screen and (max-width: 600px) and (min-width: 310px) {
  .keepMePostedTitle {
    font-size: 1.2rem;
    padding-left: 1vw;
    font-weight: light;
    color: var(--subtitle-text);
    margin-bottom: 1px;
  }
  .customSubmitRow{
       padding-bottom: 10px;
      }
  .customSubmitRow button{
      padding: 5px !important;
   }
  .keepMePostedCard{
      width: auto !important;
      margin: 7vw 7vw 20vw 7vw;
      background-color: #e0e0e0; 
  }
  .keepMePostedCard form, .keepMePostedCard form input {
      background-color: #e0e0e0 !important;
      width: 90%;
  }
}